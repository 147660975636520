.jasminka {
    display: flex;
    flex-direction: column;
    position: relative;
}

.jasm1 {
    display: flex; flex-direction: row; place-items: center;
    padding-inline: 10%;
    height: max-content;
}

.jasminka-info {
    flex: 1;
    display: flex; flex-direction: column; gap: 30px;
    place-items: center;
    border-right: #4775bf solid 6px;
}

.jasminka-info img {
    border-radius: 50%;
    border: #4775bf solid 7px;
    width: 50%;
    user-select: none;
}

.jasminka-desc {
    flex: 1;
    padding: 5%;
}

.solin-img {
    user-select: none; -webkit-user-drag: none;
    width: 50%;
    border-radius: 5px;
    margin-inline: auto;
    box-shadow: 0 0 16px 5px grey;


}

.solin-txt {
    margin-inline: 10%;
    text-align: center;
    color: gray;
    margin-bottom: 130px;
}





@media (max-width: 900px) {
    .jasm1 {
        flex-direction: column;
    }

    .jasminka-info {
        border-right: 0;
        border-bottom: #4775bf 6px solid;
        padding-bottom: 30px;
    }

    .jasminka-desc {
        padding-top: 50px;
        border-bottom: #4775bf 6px solid;

    }

    .solin-img {
        width: 80%;
        margin-top: 70px;

    }
}