body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'Old Standard TT', serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 1.4;
  width: 100%;
}

::selection {
  color: white;
  background-color: #4775bf;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: yellow;
  border-left: #4775bf 4px solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
