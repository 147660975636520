.ai {
    display: flex;
    flex-direction: row;
    background-color: #4775bf;
    color: yellow;
    padding: 10%;

    flex-wrap: wrap;
    gap: 60px;
    box-shadow: 0 0 12px 4px gray;

}

.ai-box {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.ai-box h2 {
    background-color: white;
    color: #4775bf;
    border-radius: 15px;
    padding: 5px;
    border: rgb(255, 109, 109) solid 5px;
}

.ai h1 {
    background-color: rgb(255, 109, 109);
    color: white;
    height: fit-content; max-width: 400px;

    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0 0 16px 6px silver;
}

.ai-box ul {
    list-style-type: none;
}

.graas {
    margin-right: 12px;
    font-size: 11pt;
}