.error {
    display: flex;
    flex-direction: column;
}

.wrong {
    color: white;
    background-color: crimson;
    padding-inline: 10%;
    padding-block: 40px;
    font-size: large;
    box-shadow: 0 0 16px 10px gray;
    z-index: 3;
    font-size: 24pt;
}

.correct {
    color: white;
    background-color: green;
    margin-bottom: 130px;
    padding-inline: 10%;
    padding-block: 40px;
    font-size: large;
    box-shadow: 0 0 16px 10px gray;
}

.cr-link {
text-decoration: none;
color: yellow;
}