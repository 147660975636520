/*  

rgb(255, 109, 109)
#4775bf

*/  




.cp {
    display: flex;
    flex-direction: column;
}

.cp-inner {
    display: flex; flex-direction:  column;
    gap: 60px;
    background-color: yellow;
    border-top: solid 10px #4775bf;
    padding-inline: 10%;
    padding-block: 60px;
}

.googlemap {
    border: #4775bf solid 5px;
    border-radius: 20px;
}

.links2 {
    text-decoration: none;
    color: yellow;
    background-color: #4775bf;
    width: fit-content;
    padding: 16px 32px;
    border-radius: 20px;

    transition: all 0.2s ease-out;
    box-shadow: 0 0 10px 3px grey;
}

.links2:hover {
    opacity: 0.7;
}

.apartments {
    display: flex;
    flex-direction: column;
}

.apartments h2 {
    background-color: #4775bf;
    color: yellow;
    padding: 5px;
    border-radius: 10px;
}

.apartments p {
    border-bottom: #4775bf solid 5px;
    padding-bottom: 20px;
    max-width: 70%;
}

.book {
    text-decoration: none;
    background-color: rgb(255, 109, 109);
    color: white;
    margin-bottom: 160px; margin-top: 20px;
    padding:16px 32px;
    border-radius: 5px;
    box-shadow: 0 0 12px 4px rgb(0, 0, 0, 0.5);
    width: fit-content;
    transition: all 0.2s ease-out;

}

.book:hover {
    background-color: yellow;
    color: black;
}


.imgset {
    width: 70%;
    border-radius: 10px;
    box-shadow: gray 0 0 12px 5px;
    margin-block: 30px;
}



@media (max-width: 650px) {
    .apartments p {
        max-width: 85%;
    }

    .imgset {
        width: 85%;
    }
    
}

@media (max-width: 450px) {
    .apartments p {
        max-width: 100%;
    }

    .imgset {
        width: 100%;
    }
}