.rv {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-block: 130px;
}



.rvs {
    padding-inline: 10%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; gap: 30px;
}

.rv1, .rv2, .rv3 {
    background-color: yellow;
    padding: 16px;
    outline: solid 5px rgb(255, 109, 109);     border: solid 5px #4775bf;

    border-radius: 15px;
    transition: all 0.2s ease-out;

}

.rv1:hover, .rv2:hover, .rv3:hover {
    transform: scale(0.95);
    box-shadow: 0 0 30px 10px gray;
}

.more {
    display: flex; flex-direction: column; gap: 0px;
    padding-inline: 10%; margin-top: 60px;
    border: 15px solid rgba(71, 117, 191, 0.5);
    background-color: rgba(255, 109, 109, 0.08);
    padding-bottom: 40px;
}

.more p {
    border-bottom: rgb(255, 109, 109) solid 5px;
    padding-bottom: 40px;
}

.inner-more {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;

    border-bottom: rgb(255, 109, 109) solid 5px;
    padding-bottom: 40px;
}

.inner-more h2 {
    color: #4775bf;
}

.inner-more li {
    list-style-type: none; 
    margin-block: 10px;
}

.more h3 {
    border-bottom: rgb(255, 109, 109) solid 5px;
    padding-bottom: 15px;

}

.organise {
    display: flex;
    flex-direction: row;
    gap: 50px;
    border-bottom: rgb(255, 109, 109) solid 5px;
justify-content: space-between; align-items: baseline;
margin-top: 40px;
}

.organise p {
    border-bottom: none;

}

.forest {
    transform: scale(3);
    color: green;
}

.qa {
    padding-inline: 10%;
    max-width: 50%;

}

.qa h1 {
    margin-block: 60px;
}

.qa h2 {
    color: rgb(255, 109, 109);
}

.qa p {
    margin-bottom: 40px;
    background-color: #4775bf;
    color: white;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 12px 4px silver;
}

.carousel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 80px; margin-bottom: 30px;
    gap: 7px;
    border-block: yellow solid 5px;
    outline: #4775bf solid 5px;

}

.carousel img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    
}

.rv-link {
    width: 100%;
    text-align: center; text-decoration: none;
    background-color: rgb(255, 109, 109);
    color: white;
    padding-block: 16px;
    font-size: 13pt;
    box-shadow: 0 0 12px 4px gray;
    margin-bottom: 80px;

    transition: all 0.2s ease-out;
}

.rv-link:hover {
    background-color: #4775bf;
}

.rv-last {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-inline: 10%;
    padding-block: 20px;
    gap: 40px;
}

.rv-last img {
    width: 100%;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.rv-label {
    padding-inline: 10%;
}

.rv-desc {
    margin-inline: 10%;
    background-color: yellow;
    padding: 10px;
    border: #4775bf solid 7px;

}

.rv-stars {
    margin-left: 10%;
    background-color: #4775bf;
    color: yellow;
    padding: 10px;
    width: fit-content;
    font-size: 20pt;
    border-radius: 20px;
    box-shadow: 0 0 16px 6px gray;
}

.stars:last-of-type {
    margin-right: 30px;
}



@media (max-width: 900px) {
    .rvs {
        grid-template-columns: 1fr;
    }

    .inner-more {
        grid-template-columns: 1fr;
    }

    .qa {
        max-width: 70%;
    
    }

    .rv-last {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 700px) {
    .carousel {
        grid-template-columns: 1fr;
    }

    .rv-last {
        grid-template-columns: 1fr;
    }
}


@media (max-width: 330px) {
    .rvs {
        width: 60%;
    }

  
}