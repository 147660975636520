.gallery {
    display: flex;
    flex-direction: column;
   
}

.pictures {
    background-color: yellow;
    padding-inline: 10%; padding-block: 80px;
    border-top: solid 10px #4775bf;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: hidden;
}

.pictures img {
    width: 100%;
    height: 300px;
    object-fit: cover;

    border: #4775bf solid 5px;
    position: relative;
    transition: all 0.2s ease-out;
}

.pictures img:hover {
    
    transform: scale(1.3);
    z-index: 2;
    box-shadow: 0 0 12px 4px yellow;
}



@media (max-width: 925px) {
    .pictures {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 625px) {
    .pictures {
        grid-template-columns:  1fr;
    }

    .pictures img:hover {
        transform: scale(1.15);
    }
}