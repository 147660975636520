.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.8s ease-out;

}   

.track1 {
    background-color: #4775bf;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 0px;

    box-shadow: 0 0 10px 3px grey;
    display: none;
}

.track-link, .differ {
    text-decoration: none;
    color: white;
    position: relative;
}

.track-link::after {
    content: "";
    position: absolute; 
    bottom: 0; left: 0;
    height: 3px; width: 0;
    margin-bottom: -5px;
    background-color: yellow;

    transition: all 0.4s ease-out;
}

.track-img {
    height: 80px;
    user-select: none;
}

.differ {
    background-color: yellow;
    border-radius: 10px;
    box-shadow: 0 0 10px 3px black;
    color: black;
    padding: 8px;

        transition: all 0.4s ease-out;

}

.track-link:hover.track-link::after {
    width: 100%;
}

.differ:hover {
    background-color: crimson;
    color: white;
}


.track-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: white solid 5px;
    background-color: #4775bf;
    color: white;
    padding-block: 5px;

    z-index: 5;

    transition: all 0.8s ease-out;
    position: fixed; top: 0; left:0; width: 100%;

    opacity: 0.95;

}

.track-img-2 {
    height: 50px;
    margin-block:auto;
    cursor: pointer;
    margin-left: 24px;

}

.expand {
    cursor: pointer;
    border-radius: 50%;
    border: white solid 2px;
    padding: 10px;
    transform: rotate(0deg);
    transition: all 0.5s ease-out;
    margin-right: 24px;


}

.rotation {
    cursor: pointer;
    border-radius: 50%;
    border: white solid 2px;
    padding: 10px;
    transform: rotate(90deg);
    transition: all 0.5s ease-out;
    margin-right: 24px;


}

.spreaded {
    position: absolute; top: 72px;
    display: flex; flex-direction: column;
    width: 100%;
    background-color: #4775bf;
    color: yellow;
    transform: translate(0, 0);
    height: fit-content; overflow: hidden;
    border-bottom-right-radius: 50%;
    box-shadow: 0 0 50px 20px rgb(70, 69, 69);
    transition: all 0.8s ease-out;

    z-index: 4;
position: fixed;

opacity: 0.95;



}


.hidden {
    position: absolute; top: 72px;
    display: flex; flex-direction: column;
    width: 100%;
    background-color: #4775bf;
    color: yellow;
    transform: translate(-1000px, -1000px);
    height: 0; overflow: hidden;
    border-bottom-right-radius: 50%;
    box-shadow: 0 0 16px 5px rgb(88, 88, 88);
    transition: all 0.8s ease-in;

    z-index: 4;

}



.track-link-2, .differ-2 {
    text-decoration: none;
    color: yellow;
    width: 100%;
    text-align: start;
    padding: 36px 7%;
    transition: all 0.2s ease-out;

}

.track-link-2:hover, .differ-2:hover {
    background-color: yellow;
    color: black;
}


.track2 {
    text-align: center;
    padding-block: 0px;
    color: white;
    background-color: rgb(255, 109, 109);
    text-shadow: 0 2px 2px gray;
    margin-top: 70px
/*
    position: absolute;                                     REMOVE THAT FROM HERE IMMEDIATELY
   top: 70px; left:0; width: 100%; */

}

.main-img {
    width: 100%;
    height: 60vh;
    box-shadow: 0 0 10px 3px gray;
    overflow: hidden;
    z-index: 1;
/*
    position: absolute;                                      REMOVE THAT FROM HERE IMMEDIATELY
    top: 160px; left:0; width: 100%; */
}

.main-img img {
    width: 100%; height: 100%;     object-fit: cover;
    transform: scale(1);

    transition: all 0.5s ease-out;

}

.main-img:hover.main-img img {
    opacity: 0.9;
    transform: scale(1.1);
}



.boxes {
    display: flex; flex-direction: row; gap: 20px;
    justify-content: center; align-items: center;
    width: 100%;

}

.box {
    display: flex; flex-direction: column;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px; margin-bottom: 130px;
    font-size: 11pt;
    color: rgb(99, 99, 99);

    text-align: center;
}

.box img {
    height: 26px; 
    width:26px;
}





@media (max-width: 800px) {

    .track1 {
        display: none;
    }

    .track-small {
        display: flex;
    }


    .box {
        font-size: 10pt;
    }

    .box img {
        width: 20pt;
        height: 20pt;
    }

    .main-img {
        height: 48vh;
    }

  
}


@media (max-width: 475px) {
    .box {
        font-size: 9.5pt;
    }

    .box img {
        width: 18px;
        height: 18px;
    }

}


@media (max-width: 400px) {
    .box {
        font-size: 9pt;
    }

    .box img {
        width: 17px;
        height: 17px;
    }

}