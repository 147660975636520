.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.math {
    text-decoration: none;
    color: yellow;
}

.ft1 {
background-color: yellow;
border-block: #4775bf solid 10px;
padding-inline: 10%;
padding-block: 50px;

}

.sign {
    background-color: #4775bf;
    color: white;
    padding-block: 5px;
    text-align: center;
    user-select: none;
    padding-inline: 10%;

}


.row1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;

    padding-bottom: 20px;

}

.track-link-foot {
    text-decoration: none;
    color: black;
    transition: all 0.3s ease-out;
}

.track-link-foot-2 {
    background-color: #4775bf;
    color: yellow;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 10px;
    transition: all 0.3s ease-out;

    display: flex; place-items: center;
    text-align: center;

}


.track-link-foot-2:last-of-type {
    border-radius: 50%;
}
.track-link-foot:hover {
    opacity: 0.4;
}

.track-link-foot-2:hover {
    opacity: 0.7;
}


.row2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    border-top: #4775bf solid 5px;
    padding-top: 20px;
}


.icc {
    padding: 0;
    border-radius: 50%;
    transform: scale(0.9);
}

.icc2 {
    border-radius: 50%;
    padding: 0;

}



@media (max-width: 325px) {
    .row1, .row2, .sign {
        font-size: 11pt;
    }
}