.hr {
    display: flex;
    flex-direction: column;
}

.org {
    padding-inline: 10%;
    margin-bottom: 40px;

}


.org h2 {
    background-color: rgb(255, 109, 109);
    color: white;
    padding: 10px;
    border-radius: 15px;
}

.org p {
    margin-bottom: 60px;
    max-width: 70%;
}


@media (max-width: 800px) {
    .org p {
        max-width: 90%;
    }
}